
.f-box{
text-align: center;
height: 70vh;
width:70vw ;
background-color:#040404;
border: 1px solid black;
margin-top: 20px;

}

.bg{
    background-color: #e6e1e1;
    height: 100vh;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logbtn{

    border: none;
    background-color: rgb(62, 109, 209);
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
}

.loginbtn{
    border: none;
    background-color: rgb(145, 13, 13);
    padding: 5px;
    width: 30vw;
    margin: 20px;
    color:#fff;
    font-weight: 700;
    border-radius: 5px;
}

.input{
    margin: 2px;
    padding: 2px;

}

.boder{

    height: 2px;
    background-color: aquamarine;
}

.leb{
    color: #e2dfdf;
}

