
.widgets {
    display: flex;
    gap: 20px;
    margin: 10px;
  }
  
  .widget {
    flex: 1;
    background: #a35e5e;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(224, 183, 183, 0.1);
  }
  
  .widget h3 {
    margin-top: 10;
  }

  .content{
    background-color: rgb(156, 154, 151);
    height: 100vh;
  }

  .hed{
    margin-top: 10px;
    color: #701bbe;
  }